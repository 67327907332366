import React from "react"
import PropTypes from "prop-types"

const colorPick = name => {
  switch (name) {
    case "primary":
      return "#FFFAF6"
    case "secondary":
      return "#067A72"
    case "tertiary":
      return "#0E1A50"
    default:
      return "#FFFAF6"
  }
}

function Logo({ color, ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="143" height="26" fill="none" viewBox="0 0 143 26">
      <path
        fill={colorPick(color)}
        fillRule="evenodd"
        d="M.92 0h38.712c.52 0 .92.4.918.922v3.397c0 .52-.397.92-.92.92H23.283v19.84c0 .52-.398.92-.92.92h-3.98a.904.904 0 01-.92-.92V5.236H.92a.904.904 0 01-.92-.92V.92C0 .4.398 0 .92 0zm47.345 0h3.98c.52 0 .92.4.92.92v24.158c0 .52-.399.92-.92.92h-3.98a.904.904 0 01-.92-.92V.92c0-.52.398-.92.92-.92zM94.93 0H60.875c-.522 0-.92.4-.92.92v24.158c0 .522.4.92.92.92h34.054c.522 0 .92-.4.92-.92v-3.397c0-.522-.4-.92-.92-.92H65.776v-5.433h25.469c.521 0 .92-.4.92-.92v-2.815c0-.522-.4-.92-.92-.92H65.775V5.242h29.155c.522 0 .92-.4.92-.92V.924c0-.524-.4-.924-.92-.924zm35.234 17.156l11.415 7.158a.897.897 0 01.271 1.257.874.874 0 01-.766.429h-5.124a.931.931 0 01-.491-.153l-13.592-8.69h-13.223v7.921c0 .52-.398.92-.92.92h-3.979a.904.904 0 01-.92-.92V.92c0-.52.398-.92.92-.92h25.765c6.628 0 9.695 3.165 10.402 7.214.919 5.309-1.626 9.758-9.758 9.942zM108.657 5.24v6.79h20.588c4.081 0 5.156-1.837 4.787-3.985-.276-1.565-1.535-2.805-4.787-2.805h-20.588z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

Logo.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
}

export default Logo
