import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "gatsby-plugin-intl"
import Facebook from "../../components/icons/social/Facebook"
import Instagram from "../../components/icons/social/Instagram"
import Twitter from "../../components/icons/social/Twitter"
import LinkedIn from "../../components/icons/social/LinkedIn"
import LinkChoice from "../../components/LinkChoice"
import { useReverseLanguage } from "../../components/hooks/useReverseLanguage"

export const ImprintBar = ({ className, menu, ...props }) => {
  const underlineColor = menu ? " border-green-background hover:border-blue" : " border-background hover:border-blue"

  return (
    <div {...props} className={"flex flex-col lg:flex-row gap-x-6 items-start lg:items-center text-blue text-n3 " + className}>
      {mockImprintBar.map((item, index) => {
        return (
          <LinkChoice link={item} to={item.link} className={"py-3 lg:py-2 border-b-0.5 transition" + underlineColor} key={index}>
            <FormattedMessage id={item.linkName} />
          </LinkChoice>
        )
      })}
    </div>
  )
}

ImprintBar.propTypes = {
  menu: PropTypes.bool,
}

ImprintBar.defaultProps = {
  menu: false,
}

export const SocialBar = ({ className }) => {
  return (
    <div className={"flex justify-between lg:gap-x-10 max-w-lg " + (useReverseLanguage() ? "flex-row-reverse " : "flex-row ") + className}>
      <a className="w-max hover:text-opacity-50" target="_blank" rel="noreferrer" href="https://www.facebook.com/tierscooter/">
        <Facebook />
      </a>
      <a className="w-max hover:text-opacity-50" target="_blank" rel="noreferrer" href="https://www.instagram.com/tierscooter">
        <Instagram />
      </a>
      <a className="w-max hover:text-opacity-50" target="_blank" rel="noreferrer" href="https://twitter.com/tierscooters">
        <Twitter />
      </a>
      <a className="w-max hover:text-opacity-50" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/tiermobility/">
        <LinkedIn />
      </a>
    </div>
  )
}

/**
 * DELETE after successful connection to Craft
 */

export const mockImprintBar = [
  {
    link: "/modern-slavery-act",
    linkName: "modernSlaveryLink",
    internal: true,
  },
  {
    force: true,
    link: "https://about.tier.app/impressum/",
    linkName: "imprintLink",
  },
  {
    force: true,
    link: "https://about.tier.app/privacy-notice/",
    linkName: "privacyNoticeLink",
  },
  {
    force: true,
    link: "https://about.tier.app/terms-and-conditions/",
    linkName: "termsLink",
  },
]

export const mockCompany = [
  {
    link: "/how-tier-works",
    linkName: "howTierWorksLink",
    internal: true,
  },
  {
    link: "/sustainability",
    linkName: "sustainabilityLink",
  },
  {
    link: "/where-to-find-us",
    linkName: "whereToFindUsLink",
    internal: true,
  },
  {
    link: "/jobs",
    linkName: "careerLink",
  },
]

export const mockProducts = [
  {
    link: "/business",
    linkName: "tierForBusinessLink",
    internal: true,
  },
  {
    link: "/swapspot",
    linkName: "tierEnergyLink",
  },
  {
    link: "/myTier",
    linkName: "myTierLink",
  },
]

export const mockSupport = [
  {
    link: "/contact",
    linkName: "contactLink",
    internal: true,
  },
  {
    link: "/help",
    linkName: "helpLink",
  },
]

export const mockResources = [
  {
    link: "/press",
    linkName: "pressLink",
  },
  {
    link: "/blog",
    linkName: "blogLink",
  },
  {
    link: "/engineering",
    linkName: "Engineering",
  },
]

export const mockImprint = {
  header: "Tier Mobility GmbH",
  address: `c/o WeWork<br>Eichhornstr. 3<br>10785 Berlin<br>Germany`,
  contact: `E-Mail: info@tier.app<br>Tel: +49 30 568 38651`,
}
