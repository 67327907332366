import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby-plugin-intl"
import { useLocation } from "@reach/router"

const LinkChoice = ({ link, children, ...props }) => {
  const { origin } = useLocation()

  if (link.internal) {
    return <Link {...props}>{children}</Link>
  } else {
    const { to, ...otherProps } = props

    return (
      <a href={link.force ? link.link : (origin !== undefined ? origin : "https://tierapp.netlify.app") + to} {...otherProps}>
        {children}
      </a>
    )
  }
}

LinkChoice.propTypes = {
  link: PropTypes.object.isRequired,
  to: PropTypes.string,
}

export default LinkChoice
