import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"

// Components
import RevealText from "../../components/RevealText"
import HelpIcon from "../../components/icons/HelpIcon"
import CallIcon from "../../components/icons/CallIcon"
import LinkChoice from "../../components/LinkChoice"

// Helpers
import { useWindowSize } from "../../components/hooks/useWindowSize"
import underline from "../../assets/images/underline.png"
import { useReverseLanguage } from "../../components/hooks/useReverseLanguage"

const MenuLinkList = ({ links, title, titleRef, listRef, ...props }) => {
  const { formatMessage } = useIntl()
  const { width, height } = useWindowSize()
  const iconSize = width < 1024 ? 16 : 40

  const getIcon = icon => {
    let formattedIcon = icon.toLowerCase()
    const icons = {
      help: <HelpIcon className={useReverseLanguage() ? "ml-3" : "mr-3"} width={iconSize} height={iconSize} />,
      contact: <CallIcon className={useReverseLanguage() ? "ml-3" : "mr-3"} width={iconSize} height={iconSize} />,
    }

    return icons[formattedIcon]
  }

  return (
    <div {...props} className={"flex flex-col " + (height > 1000 ? "mb-28" : "")}>
      <p className="text-gray-400 mb-5 lg:mb-9">
        <RevealText forwardedRef={titleRef} text={formatMessage({ id: title })} />
      </p>
      <div ref={ref => listRef.push(ref)}>
        {links.map(link => {
          return (
            <LinkChoice link={link} key={link.linkName} to={link.link} className="relative flex text-blue items-center mb-5 lg:mb-12">
              {link.icon && getIcon(link.icon)}
              <div className="group relative">
                <p className="font-extended relative z-10 text-p2 lg:text-n1 lg:font-extrabold text-blue">{formatMessage({ id: link.linkName })}</p>
                <img
                  alt="underline"
                  src={underline}
                  className="absolute z-0 -bottom-2 left-0 w-full h-4 opacity-0 group-hover:opacity-100 transition-opacity"
                />
              </div>
            </LinkChoice>
          )
        })}
      </div>
    </div>
  )
}

MenuLinkList.propTypes = {
  links: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  titleRef: PropTypes.array,
  listRef: PropTypes.array,
}

export default MenuLinkList
