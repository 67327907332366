import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import AccordionItem from "./AccordionItem"
import AccordionItemLinks from "./AccordionItemLinks"
import AccordionItemKPI from "./AccordionItemKPI"

const AccordionWrapper = styled.section`
  li {
    max-width: 1920px;
    list-style-type: none;
    /* border-bottom-width: 1px;
    border-color: #D9D9D9; */
    margin: auto;
  }
`

const Accordion = ({ items, variant, close, ...props }) => {
  const { className, ...addonProps } = props
  const [activeItem, setActiveItem] = useState(null)
  return (
    <AccordionWrapper className={className || ""} {...addonProps}>
      {items.map((item, index) => {
        switch (variant) {
          case "links":
            return (
              <AccordionItemLinks
                key={item.title + index}
                title={item.title}
                links={item.links}
                selectedItem={activeItem}
                toggleContent={setActiveItem}
                index={index}
                close={close}
              />
            )
          case "kpi":
            return (
              <AccordionItemKPI
                key={item.title + index}
                title={item.title}
                content={item.content}
                selectedItem={activeItem}
                toggleContent={setActiveItem}
                index={index}
              />
            )
          case "default":
          default:
            return (
              <AccordionItem
                key={item.header + index}
                header={item.header}
                body={item.body}
                selectedItem={activeItem}
                toggleContent={setActiveItem}
                index={index}
                link={item.link}
              />
            )
        }
      })}
    </AccordionWrapper>
  )
}
Accordion.propTypes = {
  items: PropTypes.array,
  close: PropTypes.func,
  variant: PropTypes.oneOf(["default", "links", "kpi"]),
}

Accordion.defaultProps = {
  items: [],
  variant: "default",
}

export default Accordion
