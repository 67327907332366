import React from "react"
import PropTypes from "prop-types"

function CallIcon({ color, width, height, ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        className="stroke-current"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M11.45 25.25c4.566 7.917 12.232 10.75 17.166 7.9 3.733-2.167.866-4.433-1.817-6.583-2.683-2.15-2.783.166-5.1.566-1.15.2-2.517-.966-3.583-2.166a15.302 15.302 0 01-3.067-5.317c-.517-1.533-.833-3.283-.083-4.2 1.5-1.8 3.566-.733 3.033-4.133-.533-3.4-1.05-7.017-4.8-4.867-4.933 2.833-6.317 10.867-1.75 18.8zM23.566 14.733a7.249 7.249 0 012.134 2.3c.55.95.85 1.967.95 2.984M24.816 10.033a12.01 12.01 0 014.917 4.667c1.2 2.067 1.7 4.333 1.583 6.567"
      ></path>
    </svg>
  );
}

CallIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

CallIcon.defaultProps = {
  color: "#0E1A50",
  width: 40,
  height: 40,
}

export default CallIcon;
