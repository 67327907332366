// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-how-tier-works-js": () => import("./../../../src/pages/how-tier-works.js" /* webpackChunkName: "component---src-pages-how-tier-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-israel-js": () => import("./../../../src/pages/israel.js" /* webpackChunkName: "component---src-pages-israel-js" */),
  "component---src-pages-modern-slavery-act-js": () => import("./../../../src/pages/modern-slavery-act.js" /* webpackChunkName: "component---src-pages-modern-slavery-act-js" */),
  "component---src-pages-where-to-find-us-js": () => import("./../../../src/pages/where-to-find-us.js" /* webpackChunkName: "component---src-pages-where-to-find-us-js" */)
}

