import React from 'react';
import styled from "styled-components"
import PropTypes from "prop-types"

const Card = styled.div`
  @media (min-width: 768px) {
    width: 212px;
    min-height: 62px;
  }
`

const KPICard = ({ title }) => {
  return (
    <Card className="flex items-center justify-center rounded-xl md:bg-blue-background">
      <p className="text-p2 text-gray-500 text-center md:text-blue md:font-bold md:px-4 whitespace-pre-line">{title}</p>
    </Card>
  )
}

KPICard.propTypes = {
  title: PropTypes.string.isRequired,
}

export default KPICard;