import { useState, useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"

export function useReverseLanguage() {
  const { locale } = useIntl()
  const [reverseLanguage, setReverseLanguage] = useState(false)

  useEffect(() => {
    let reverseLang = locale === "ar" || locale === "he"
    setReverseLanguage(reverseLang)
  }, [locale])

  return reverseLanguage
}
