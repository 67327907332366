/* eslint-disable no-useless-concat */
import React from "react"
import PropTypes from "prop-types"
import { classes } from "../../utils/paloma-helpers"

const Size = {
  base: "base",
  lg: "lg",
}

const Color = {
  primary: "primary",
  secondary: "secondary",
}

const Variant = {
  filled: "filled",
  outline: "outline",
}

/**
 * Primary Button with variants
 */
const PalButton = ({ label, action, iconPrefix, iconSuffix, disabled, size, color, block, variant, ...props }) => {
  const { className, ...addonProps } = props
  const colorOptions = {
    "border border-blue bg-blue text-white hover:bg-transparent hover:text-blue": color === "primary" && variant === "filled",
    "border border-white bg-white text-blue hover:bg-transparent hover:text-white": color === "secondary" && variant === "filled",
    "border border-blue text-blue hover:bg-transparent hover:text-white": color === "primary" && variant === "outline",
    "border border-white text-white hover:bg-transparent hover:text-blue": color === "secondary" && variant === "outline",
    "w-full": block,
    "opacity-50 pointer-events-none cursors-not-allowed": disabled,
    "h-14": size === Size.lg,
    "h-12": size === Size.base,
    "pl-5 pr-7": iconPrefix && !iconSuffix,
    "pl-7 pr-5": !iconPrefix && iconSuffix,
    "px-5": (!iconPrefix && !iconSuffix) || (iconSuffix && iconPrefix),
  }

  return (
    <button
      {...addonProps}
      disabled={disabled}
      className={classes(
        colorOptions,
        className +
          " " +
          "flex text-b1 font-bold rounded-lg justify-center items-center cursor-pointer transition-colors focus:outline-none focus:ring focus:ring-focus"
      )}
      onClick={action}
    >
      {iconPrefix}
      <span className="mb-0.5">{label}</span>
      {iconSuffix}
    </button>
  )
}

PalButton.propTypes = {
  /**
   * The text for the Button
   */
  label: PropTypes.string.isRequired,
  /**
   * Function called whenever the button is clicked
   */
  action: PropTypes.func,
  /**
   * Icon positioned before label
   */
  iconPrefix: PropTypes.node,
  /**
   * Icon positioned after label
   */
  iconSuffix: PropTypes.node,
  /**
   * Toggle button usage
   */
  disabled: PropTypes.bool,
  /**
   * Defines color based on config
   */
  color: PropTypes.oneOf([Color.primary, Color.secondary]),
  /**
   * Defines button type
   */
  variant: PropTypes.oneOf([Variant.filled, Variant.outline]),
  /**
   * Defines between all sizes
   */
  size: PropTypes.oneOf([Size.base, Size.lg]),
  /**
   * Choose between block modes
   */
  block: PropTypes.bool,
}

PalButton.defaultProps = {
  disabled: false,
  block: false,
  size: Size.base,
  color: Color.primary,
  variant: Variant.filled,
}

export default PalButton
