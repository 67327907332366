// Dependencies
import React, { useEffect, useState } from "react"
import { Pivot as Hamburger } from "hamburger-react"
import PropTypes from "prop-types"
import { Link, useIntl } from "gatsby-plugin-intl"

// Components
import PalButton from "../../components/buttons/PalButton"
import Container from "../../components/containers/Container"
import Logo from "../../components/icons/Logo"

// Hooks & Helpers
import { setEventListener } from "../../utils/helpers"
import { useIsMacOS } from "../../components/hooks/useIsMacOS"
import DownloadIcon from "../../components/icons/DownloadIcon"
import LanguageSwitcher from "../../components/LanguageSwitcher"

const NavBar = ({ toggleMenu, isOpen }) => {
  // States & Refs
  const { formatMessage } = useIntl()
  const [theme, setTheme] = useState("light")
  const [scrolled, setScrolled] = useState(false)
  const [hovered, setHovered] = useState(false)
  const [, setForceLogoColor] = useState(null)

  let isAppleDevice = useIsMacOS()
  let appUrl = isAppleDevice
    ? "https://apps.apple.com/app/id1436140272?mt=8"
    : "https://play.google.com/store/apps/details?id=com.tier.app&hl=en&gl=US"
  let originalTheme = ""

  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 88) {
      setScrolled(true)
      if (originalTheme === "light") setTheme("dark")
    } else {
      setScrolled(false)
      if (originalTheme === "light") setTheme("light")
    }
  }

  useEffect(() => {
    // Runs only once
    setEventListener("nav-scheme", e => {
      setTheme(e.detail)
      originalTheme = e.detail
    })
    setEventListener("force-logo", e => setForceLogoColor(e.detail))
    window.addEventListener("scroll", handleScroll)

    return () => {
      setEventListener("nav-scheme", e => setTheme(e.detail), true)
      setEventListener("force-logo", e => setForceLogoColor(e.detail), true)
    }
  }, [])

  return (
    <Container
      fullWidth
      type="wide"
      className={
        "flex items-center h-16 md:h-18 fixed top-0 left-0 z-50 w-screen transition " +
        (scrolled && !isOpen ? "bg-background shadow-nav" : "bg-transparent shadow-none")
      }
    >
      <div className="flex flex-1 justify-start" onClick={() => toggleMenu(false)}>
        <Link to="/">
          <Logo color={theme === "dark" || isOpen ? "tertiary" : "primary"} className="h-8 w-28 md:h-auto md:w-auto" />
        </Link>
      </div>
      <div className="flex flex-1 items-center justify-end gap-x-4 cursor-pointer -mr-2">
        <LanguageSwitcher
          className={isOpen ? "block " : "hidden sm:block transition "}
          theme={isOpen ? "dark" : theme}
          toggleMenu={() => toggleMenu(false)}
        />
        <a href={appUrl} className="hidden lg:flex lg:mr-4">
          <PalButton
            iconPrefix={<DownloadIcon color={theme === "dark" || isOpen ? "secondary" : "primary"} hovered={hovered} />}
            label={formatMessage({ id: "tierAppCta" })}
            color={theme === "dark" || isOpen ? "primary" : "secondary"}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          />
        </a>
        <Hamburger size={28} color={theme === "dark" || isOpen ? "#0E1A50" : "#fff"} toggled={isOpen} toggle={toggleMenu} />
      </div>
    </Container>
  )
}

NavBar.propTypes = {
  toggleMenu: PropTypes.func,
  isOpen: PropTypes.bool,
}

NavBar.defaultProps = {
  scheme: "light",
}

export default NavBar
