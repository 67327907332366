import React from "react"
import PropTypes from "prop-types"

function HelpIcon({ color, width, height, ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        className="stroke-current"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M6.783 14.2l2.9-1.033c1.983-.667 4.084-.034 5.633 1.683l.717.783c1.583 1.767 3.6 2.784 5.7 2.867l6.534.283"
      ></path>
      <path
        className="stroke-current"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M6.783 23.967l6 4.8c3.25 2.6 7.35 2.716 10.684.316L37.65 18.867c.617-.45.85-1.434.533-2.25l-.2-.5c-.666-1.717-2.283-2.384-3.583-1.484l-8.217 5.6c-2.683 1.834-5.633 2.934-8.65 3.25l-1.55.167"
      ></path>
      <path
        className="stroke-current"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M5.116 29.667H3.333c-.917 0-1.667-.75-1.667-1.667V10.883c0-.916.75-1.666 1.667-1.666h1.783c.917 0 1.667.75 1.667 1.666V28a1.66 1.66 0 01-1.667 1.667z"
      ></path>
    </svg>
  );
}

HelpIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

HelpIcon.defaultProps = {
  color: "#0E1A50",
  width: 40,
  height: 40,
}

export default HelpIcon;
