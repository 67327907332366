// Dependencies
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import gsap from "gsap"

// Components
import { MenuContainer } from "./Menu.styles"
import Container from "../../components/containers/Container"
import Accordion from "../Accordion/Accordion"
import MenuLinkList from "./MenuLinkList"
import { menuLists } from "../../utils/content/menuContent"

// Helpers
import { BodyLockContext } from "../../components/contexts/BodyLockContext"
import { ImprintBar, SocialBar } from "../Footer/FooterBars"
import { useWindowSize } from "../../components/hooks/useWindowSize"
import { useIsMacOS } from "../../components/hooks/useIsMacOS"
import PalButton from "../../components/buttons/PalButton"
import DownloadIcon from "../../components/icons/DownloadIcon"

const Menu = ({ menuContent, isOpen, isAnimating, toggleMenu }) => {
  // States & Refs
  const [, setBodyLock] = useContext(BodyLockContext)
  let [initialized, setInitialized] = useState(false)
  let { current: containerEl } = useRef(null)
  let { current: menuFooterEl } = useRef(null)
  let { current: accordionEl } = useRef(null)
  let { current: headlinesEl } = useRef([])
  let { current: listsEl } = useRef([])
  let timeline = useMemo(() => gsap.timeline({ paused: true }), [])
  let width = useWindowSize().width
  let isAppleDevice = useIsMacOS()
  let appUrl = isAppleDevice
    ? "https://apps.apple.com/app/id1436140272?mt=8"
    : "https://play.google.com/store/apps/details?id=com.tier.app&hl=en&gl=US"

  // Separate menuContent in two arrays to render the desktop columns separately
  // This way we don't need to make an unnecessarily complex module in Craft
  const contentA = menuContent.slice(0, menuContent.length - 2)
  const contentB = menuContent.slice(-2)

  useEffect(() => {
    // Runs only once
    setIntialState(containerEl, isAnimating)
  }, [])

  useEffect(() => {
    // Toggle animation state
    if (!initialized) return

    if (isOpen) {
      openMenu()
    } else {
      closeMenu()
    }
  }, [isOpen])

  // Animations
  const setIntialState = element => {
    gsap.set(element, { y: "-100%" })
    setBodyLock(false)

    if (!initialized) setInitialized(true)
  }

  const openMenu = () => {
    setBodyLock(true)
    animateContent()
    gsap.to(containerEl, {
      y: "0%",
      ease: "power3.out",
      duration: 1,
    })
  }

  const closeMenu = () => {
    width >= 1024
      ? timeline
          .clear()
          .addLabel("start", 0)
          .to(headlinesEl, { y: "100%", opacity: 0, duration: 0.5, ease: "power3", stagger: 0.1 }, "start")
          .to(menuFooterEl, { y: "100%", opacity: 0, duration: 0.5, ease: "power3", stagger: 0.1 }, "start")
          .to(listsEl, { y: "50px", opacity: 0, duration: 0.5, ease: "power3", stagger: 0.1 }, "start+=0.1")
          .to(
            containerEl,
            {
              y: "-100%",
              duration: 0.75,
              delay: 0.5,
              onComplete: containerEl => {
                setIntialState(containerEl)
              },
              onCompleteParams: [containerEl],
            },
            "start"
          )
          .play()
      : timeline
          .clear()
          .addLabel("start", 0)
          .to(accordionEl, { x: "50%", opacity: 0, duration: 0.4, ease: "power3", stagger: 0.1 }, "start")
          .to(menuFooterEl, { y: "100%", opacity: 0, duration: 0.5, ease: "power3", stagger: 0.1 }, "start")
          .to(
            containerEl,
            {
              y: "-100%",
              duration: 0.75,
              delay: 0.5,
              onComplete: containerEl => {
                setIntialState(containerEl)
              },
              onCompleteParams: [containerEl],
            },
            "start"
          )
          .play()
  }

  const animateContent = () => {
    width >= 1024
      ? timeline
          .clear()
          .addLabel("start", 0.5)
          .set(headlinesEl, { y: "100%", opacity: 0 })
          .set(menuFooterEl, { y: "100%", opacity: 0 })
          .set(listsEl, { y: "50px", opacity: 0 })
          .to(headlinesEl, { y: "0%", opacity: 1, duration: 1, ease: "power3", stagger: 0.1 }, "start")
          .to(menuFooterEl, { y: "0%", opacity: 1, duration: 1, ease: "power3", stagger: 0.1 }, "start")
          .to(listsEl, { y: "0px", opacity: 1, duration: 1, ease: "power3", stagger: 0.1 }, "start+=0.1")
          .play()
      : timeline
          .clear()
          .addLabel("start", 0.5)
          .set(accordionEl, { x: "50%", opacity: 0 })
          .set(menuFooterEl, { y: "100%", opacity: 0 })
          .to(accordionEl, { x: "0%", opacity: 1, duration: 0.8, ease: "power3", stagger: 0.1 }, "start")
          .to(menuFooterEl, { y: "0%", opacity: 1, duration: 1, ease: "power3", stagger: 0.1 }, "start")
          .play()
  }

  return (
    <MenuContainer
      isOpen={isOpen}
      className={"fixed left-0 top-0 z-30 w-screen h-fit-height " + (isOpen ? "pointer-events-auto show" : "pointer-events-none")}
    >
      <div ref={ref => (containerEl = ref)} className="w-full h-full bg-green-background absolute" />

      <Container className="h-full pt-24 md:pt-40 lg:pt-0 flex flex-col justify-start items-start lg:items-end z-10" type="wide">
        <div className="flex flex-grow lg:flex-grow-0 w-full lg:h-1/2 lg:pr-16 max-w-screen-2xl lg:m-auto">
          {width >= 1024 ? (
            <div className="flex w-full justify-between items-start">
              {contentA.map(list => (
                <MenuLinkList onClick={toggleMenu} title={list.title} links={list.links} titleRef={headlinesEl} listRef={listsEl} key={list.title} />
              ))}
              <div className="small-list flex flex-col justify-start items-start h-full text-blue space-y-8">
                {contentB.map(list => (
                  <MenuLinkList
                    onClick={toggleMenu}
                    title={list.title}
                    links={list.links}
                    titleRef={headlinesEl}
                    listRef={listsEl}
                    key={list.title}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="w-full z-20" ref={ref => (accordionEl = ref)}>
              <Accordion className="w-full" items={menuLists} variant="links" close={toggleMenu} />
            </div>
          )}
        </div>
        <div
          ref={ref => (menuFooterEl = ref)}
          className="flex flex-col lg:flex-row justify-between w-full lg:px-5 pb-8 pt-7 lg:pt-10 max-w-screen-2xl mx-auto"
        >
          <ImprintBar onClick={toggleMenu} className="hidden lg:flex order-last lg:order-first text-blue" menu />
          <SocialBar className="text-blue mx-auto justify-between w-full xs:w-auto xs:gap-x-10 lg:mx-0 lg:gap-x-0" />
          <a href={appUrl} className="lg:hidden">
            <PalButton
              className="w-full max-w-screen-xs mt-6 mx-auto"
              iconPrefix={<DownloadIcon color="secondary" />}
              label={"Download the app"}
              color="primary"
            />
          </a>
        </div>
      </Container>
    </MenuContainer>
  )
}

Menu.propTypes = {
  /** TODO make menuContent required when connected to Craft */
  menuContent: PropTypes.array,
  isOpen: PropTypes.bool,
  isAnimating: PropTypes.bool,
  toggleMenu: PropTypes.func,
}

Menu.defaultProps = {
  menuContent: menuLists,
}

export default Menu
