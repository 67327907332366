import React from "react"
import PropTypes from "prop-types"
import Logo from "../../components/icons/Logo"
import { SocialBar, ImprintBar } from "./FooterBars"
import BadgeAppStore from "../../components/icons/BadgeAppStore"
import BadgeGooglePlay from "../../components/icons/BadgeGooglePlay"
import ListBlock from "../../components/containers/ListBlock"
import LanguageSwitcher from "../../components/LanguageSwitcher"
import { useIntl } from "gatsby-plugin-intl"
import { menuLists } from "../../utils/content/menuContent"

const Footer = () => {
  const { formatMessage } = useIntl()

  return (
    <div className="flex flex-col px-6 pt-20 md:px-12 xl:px-20">
      {/* Logo block */}
      <div className="flex justify-between items-center pb-8 w-full max-w-screen-2xl m-auto">
        <Logo color="tertiary" />
        <LanguageSwitcher footer />
      </div>

      {/* Upper footer block */}
      <div className="flex flex-col md:grid md:grid-cols-3 lg:flex lg:flex-row text-blue py-12 justify-between lg:align-start space-y-12 md:space-y-0 md:gap-y-12 max-w-screen-2xl lg:mx-auto w-full">
        <div>
          <ListBlock title="linkColumnOneTitle" list={menuLists[0].links} />
        </div>

        <div>
          <ListBlock title="linkColumnTwoTitle" list={menuLists[1].links} />
        </div>

        <div>
          <ListBlock title="linkColumnFourTitle" list={menuLists[2].links} />
        </div>

        <div>
          <ListBlock title="linkColumnThreeTitle" list={menuLists[3].links} />
        </div>

        <div className="flex flex-row flex-wrap justify-between md:flex-col md:justify-start space-y-4 md:space-y-3 max-w-lg text-blue">
          <p className="font-extended w-full text-p3 font-extrabold mb-2 lg:mb-3">{formatMessage({ id: "tierAppCta" })}</p>
          <div className="border-2 border-blue rounded-md md:w-full" style={{ width: 194 }}>
            <BadgeAppStore />
          </div>
          <div className="border-2 border-blue rounded-md md:w-max">
            <BadgeGooglePlay />
          </div>
        </div>
      </div>

      {/* Lower footer block */}
      <div className="flex flex-col md:flex-row justify-between w-full border-t border-blue border-opacity-20 pb-12 pt-7 lg:pt-10 max-w-screen-2xl m-auto">
        <ImprintBar className="order-last md:order-first md:flex-row" />
        <SocialBar className="text-blue md:gap-x-6" />
        {/* Divider for mobile */}
        <div className="block md:hidden border-b border-blue opacity-20 pt-7 mb-12" />
      </div>
    </div>
  )
}

Footer.propTypes = {
  propContent: PropTypes.object,
  id: PropTypes.string,
}

export default Footer
