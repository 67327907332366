import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"
import LinkChoice from "../LinkChoice"

const ListBlock = ({ title, list, listRef, ...props }) => {
  const { formatMessage } = useIntl()
  return (
    <div ref={ref => (listRef ? listRef.push(ref) : null)} className="flex flex-col text-p3 justify-start" {...props}>
      <p className="font-extended font-extrabold text-p3">{formatMessage({ id: title })}</p>
      <ul>
        {list.map((item, i) => {
          return (
            <li className="w-max my-4 text-gray-500" key={item.linkName || item.name + "-" + i}>
                <LinkChoice link={item} key={item.linkName || item.name + "-" + i} to={item.link}>
                  {formatMessage({ id: item.linkName || item.name })}
                </LinkChoice>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

ListBlock.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  listRef: PropTypes.array,
  darkMode: PropTypes.bool,
}

export default ListBlock
