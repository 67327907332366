import React, { useEffect, useState, useCallback, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import gsap from "gsap"

// Components
import ChevronDown from "../../components/icons/ChevronDown"
import KPICard from "../../components/KPICard"

const CardsWrapper = styled.div`
  width: 100%;
  max-width: 720px;
  @media (min-width: 1024px) {
    width: 960px;
  }
`

const AccordionItemKPI = ({ title, content, selectedItem, index, toggleContent, ...props }) => {
  const { className, ...addonProps } = props
  const [active, setActive] = useState(true)
  const contentRef = useRef()
  const headerRef = useRef()

  /**
   * Methods
   */
  const toggleItem = () => {
    const newValue = selectedItem === index ? null : index
    setActive(newValue === index)
    toggleContent(newValue)
  }

  const onKeyUpAction = e => {
    if (e.key === "Enter" || e.key === "Space") toggleItem()
  }
  const onKeyDownAction = e => {
    if (e.key === "Escape") {
      toggleContent(null)
      setActive(false)
    }
  }

  const animate = useCallback(() => {
    const height = active ? "auto" : 0
    const opacity = active ? 1 : 0
    gsap.to(contentRef.current, { height, opacity, duration: 0.3 })
  }, [active, contentRef])

  /** Run on component mount */
  useEffect(() => {
    if (active) animate()
  }, [])

  /** Run whenever the item is updated */
  useEffect(() => {
    animate()
  }, [active, animate])

  /** Close item if other accordeon item was selected */
  useEffect(() => {
    if (active && selectedItem !== index) setActive(false)
  }, [selectedItem])

  /**
   * Component
   */
  return (
    <li className={className} {...addonProps}>
      <div
        ref={headerRef}
        className="text-blue cursor-pointer flex justify-center items-center gap-x-4 py-5 md:py-8 focus:outline-none transition"
        role="button"
        tabIndex="0"
        aria-haspopup="listbox"
        aria-expanded={active ? "true" : "false"}
        onClick={toggleItem}
        onKeyUp={onKeyUpAction}
        onKeyDown={onKeyDownAction}
      >
        <ChevronDown className={"mt-1 transition " + (active ? "transform rotate-180" : "")} />
        <h5 className="text-h7 lg:text-h5 font-extended">{title}</h5>
        <div className="bg-orange-highlight rounded-full pt-0.5 pb-1.5 px-2 lg:pt-1 lg:pb-2 lg:px-3">
          <p className="text-n3">{content.length}</p>
        </div>
      </div>
      <div ref={contentRef} className="h-0 opacity-0 overflow-hidden flex items-center" role="listbox">
        <CardsWrapper
          className={
            "flex flex-col md:flex-row flex-wrap gap-y-3 md:gap-y-8 gap-x-7 py-4 max-w-screen-lg mx-auto" +
            (content.length < 4 ? " justify-center" : "")
          }
        >
          {content.map((card, index) => (
            <KPICard key={index} title={card.title} />
          ))}
        </CardsWrapper>
      </div>
    </li>
  )
}

AccordionItemKPI.propTypes = {
  /**
   * Item header's text
   */
  title: PropTypes.string.isRequired,
  /**
   * Item's content
   */
  content: PropTypes.array.isRequired,
  /**
   * The currently selected item of the accordeon
   */
  selectedItem: PropTypes.number,
  /**
   * This item's index number inside the accordeon
   */
  index: PropTypes.number.isRequired,
  /**
   * Function to update the accordeon state
   */
  toggleContent: PropTypes.func.isRequired,
}

AccordionItemKPI.defaultProps = {
  selectedItem: null,
}

export default AccordionItemKPI
