import React from "react"
import PropTypes from "prop-types"

const Container = ({ children, className, type, fullWidth, fullMobile, id }) => {
  return (
    <div
      className={
        "mx-auto " +
        (fullMobile ? "px-0 " : "px-6 ") +
        (type === "wide" ? "md:px-24" : "md:px-24 lg:px-32 2xl:px-48") +
        " " +
        (!fullWidth ? "max-w-screen-3xl " : "") +
        (className ? " " + className : "")
      }
      id={id}
    >
      {children}
    </div>
  )
}

Container.propTypes = {
  type: PropTypes.oneOf(["wide", "normal"]),
  fullWidth: PropTypes.bool,
  fullMobile: PropTypes.bool,
  id: PropTypes.string,
}

Container.defaultProps = {
  type: "normal",
}

export default Container
