// Dependencies
import React, { useEffect, useState } from "react"

// Components
import NavBar from "../../modules/NavBar/NavBar"
import Menu from "../../modules/Menu/Menu"
import Footer from "../../modules/Footer/Footer"

// Helpers
import { BodyLockContextProvider } from "../contexts/BodyLockContext"
import { setGlobalVH, updateGlobalVH } from "../../utils/helpers"

const Layout = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)

  const toggleMenu = force => {
    if (force !== undefined && force !== null && typeof force !== "object") {
      setMenuOpen(force)
      setIsAnimating(true)
      return
    }

    if (!isAnimating) setMenuOpen(!menuOpen)

    setIsAnimating(true)
  }

  useEffect(() => {
    // Time animation offset
    if (isAnimating) {
      setTimeout(() => setIsAnimating(false), 1000)
    }
  }, [isAnimating])

  useEffect(() => {
    setGlobalVH()
    window.addEventListener("resize", updateGlobalVH)

    return () => {
      window.removeEventListener("resize", updateGlobalVH)
    }
  }, [])

  return (
    <BodyLockContextProvider>
      <main className="bg-background">
        <NavBar toggleMenu={toggleMenu} isOpen={menuOpen} isAnimating={isAnimating} />
        <Menu isOpen={menuOpen} isAnimating={isAnimating} toggleMenu={toggleMenu} />
        {children}
        <Footer />
      </main>
    </BodyLockContextProvider>
  )
}

export default Layout
