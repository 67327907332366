import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
// import { flag } from "country-emoji"
import { useIntl } from "gatsby-plugin-intl"

import { languages } from "../utils/content/menuContent"
import { useClickOutside } from "./hooks/useClickOutside"
import { useReverseLanguage } from "./hooks/useReverseLanguage"

import check from "../assets/images/check.svg"

const LanguageSwitcher = ({ className, toggleMenu, theme }) => {
  const container = useRef(null)
  const { locale } = useIntl()
  const [open, setOpen] = useState(false)

  useClickOutside(container, () => setOpen(false), open)

  return (
    <div ref={container} className={"relative " + className}>
      <button
        className={"relative w-12 h-12 rounded-lg border flex items-center justify-center " + (theme === "light" ? "border-white" : "border-blue")}
        onClick={() => setOpen(!open)}
      >
        <span className={"mb-1 " + (theme === "light" ? "text-white" : "text-blue")}>
          {languages.filter(e => e.code === locale)[0].code.toUpperCase()}
        </span>
      </button>
      <div
        className={
          "absolute w-48 h-auto bg-white rounded-lg px-6 py-2 z-40 shadow-nav overflow-y-auto top-16 " +
          (useReverseLanguage() ? "left-0 " : "right-0 ") +
          (open ? "block" : "hidden")
        }
        style={{ maxHeight: 385 }}
      >
        {languages.map(lang => {
          if (lang.language === "Hebrew" || lang.language === "Italian") return

          return (
            <a key={lang.code} href={"https://www.tier.app/" + lang.code + "/"}>
              <button
                className="relative flex py-2 gap-x-4 items-center h-11 w-full"
                onClick={() => {
                  // changeLocale(lang.code)
                  setOpen(false)
                  toggleMenu()
                }}
              >
                {/* <p>{flag(lang.flag)}</p> */}
                <p className={"hover:text-blue-informative text-p3 " + (locale === lang.code ? "text-blue-informative" : "text-gray-500")}>
                  {lang.language}
                </p>
                {locale === lang.code && <img alt="check" src={check} className={"absolute " + (useReverseLanguage() ? "left-0" : "right-0")} />}
              </button>
            </a>
          )
        })}
      </div>
    </div>
  )
}

LanguageSwitcher.propTypes = {
  toggleMenu: PropTypes.func,
  theme: PropTypes.string,
}

LanguageSwitcher.defaultProps = {
  toggleMenu: () => null,
  theme: "dark",
}
export default LanguageSwitcher
